
export default {
  name: 'HeroUnit',
  props: {
    title: {
      type: String,
      required: true,
    },
    image: {
      type: String,
      required: true,
    },
    imageTitle: {
      type: String,
      required: true,
    },
  },
  methods: {
    replaceInTitleForAlt(title) {
      return title.replace('grafika dla', 'grafika')
    }
  },
}
