
import SEO from '~/mixins/SEO.js'

export default {
  name: 'AktualnosciPage',
  mixins: [SEO],
  async asyncData({ app }) {
    let posts = []
    try {
      posts = await app.$wp.posts().perPage(4).page(1)
      for (let index = 0; index < posts.length; index++) {
        const post = posts[index]
        const images = post.content.rendered.match(
          /<img[^>]* src="([^"]*)"[^>]*>/gi
        )
        const links = post.content.rendered.match(/<a\b[^>]*>([\s\S]*?)<\/a>/gmi)
        const newLinks = []
        if (links) {
          for (let index = 0; index < links.length; index++) {
            const element = links[index]
            const anchorArray = element.match(/<a\b[^>]*>([\s\S]*?)<\/a>/i)
            const anchor = anchorArray[1].trim()
            newLinks.push(
              (!element.includes('class=')
                ? element.replace(
                    'href=',
                    'class="bbit-link" href='
                  )
                : element.replace('class="', 'class="bbit-link')
              ).replace(
                'href=',
                !element.includes('//bielbit.pl/') &&
                  !element.includes('//www.bielbit.pl/')
                  ? 'title="Przejdź do strony ' + anchor + '" rel="nofollow" href='
                  : 'title="Przejdź do strony ' + anchor + '" href='
              )
            )
          }
          for (let index = 0; index < links.length; index++)
            post.content.rendered = post.content.rendered.replace(
              links[index],
              newLinks[index]
            )
        }
        for (let index = 0; index < images.length; index++)
          post.content.rendered = post.content.rendered.replace(
            images[index],
            ''
          )
        for (let index = 0; index < images.length; index++)
          images[index] = images[index]
            .match(/src=".*\..{3,4}"/gi)[0]
            .replace(/src=/gi, '')
            .replace(/"/g, '')
            .replace(
              'https://projektblog.bielbit.pl/wp-content/',
              '/projekt-wp-content/'
            )
        posts[index].images = images
      }
    } catch (error) {
      console.error(error)
    }
    return {
      posts,
    }
  },
  data() {
    return {
      heroUnitImg: '/news.png',
      rawPageTitle: 'Aktualności',
      rawPageDescription:
        'Na tej stronie możesz się zapoznać z naszymi aktualnościami firmowymi.',
      keywords: [
        'insert',
        'firma',
        'czerwiec',
        'rozwiązanie',
        'program',
        'bielbit',
        'promocja',
        'pro',
        'oprogramowanie',
        'potrzeba',
        'gt',
        'nowy',
        'obrazek',
        'wpis',
      ],
    }
  },
  // head() {
  //   const head = {
  //     link: [],
  //   }
  //   head.link.push({
  //     rel: 'preload',
  //     as: 'fetch',
  //     href: `https://blog.bielbit.pl/wp-json/wp/v2/posts?page=1&per_page=4`,
  //   })
  //   return head
  // },
  computed: {
    bgImage() {
      return this.$device.isSamsung || this.$device.userAgent?.includes('OPR/')
        ? ''
        : this.$colorMode.preference === 'light'
        ? 'background-image: url(/trojkat-tlo-jasne.png);'
        : 'background-image: url(/trojkat-tlo-ciemne.png);'
    },
  },
}
