import { render, staticRenderFns } from "./StickyFooter.vue?vue&type=template&id=5a0a4716"
import script from "./StickyFooter.vue?vue&type=script&lang=js"
export * from "./StickyFooter.vue?vue&type=script&lang=js"
import style0 from "./StickyFooter.vue?vue&type=style&index=0&id=5a0a4716&prod&lang=css"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BootstrapIcon: require('/var/www/html/bielbit/www2022/upd/components/BootstrapIcon.vue').default,TelTo: require('/var/www/html/bielbit/www2022/upd/components/TelTo.vue').default,MailTo: require('/var/www/html/bielbit/www2022/upd/components/MailTo.vue').default})
